import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { MaterialManifestModule } from '../material-manifest/material-manifest.module';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { BasicPopupComponent } from './components/basic-popup/basic-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    MaterialManifestModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    BasicPopupComponent,
  ],
  entryComponents: [
    BasicPopupComponent,
  ]
})
export class SharedModule { }
