import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  animate,
  state,
  style,
  query,
  stagger,
  transition,
  trigger
} from '@angular/animations';
import { Intercom } from 'ng-intercom';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  ValidatorFn,
  AbstractControl,
  FormGroupDirective,
  NgForm
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {
  map,
  catchError,
  tap,
  filter
} from 'rxjs/operators';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import * as phoneLib from 'google-libphonenumber';
import { ErrorStateMatcher, MatDialog } from '@angular/material';
import { BasicPopupComponent } from './shared/components/basic-popup/basic-popup.component';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control.dirty || control.touched ) && form.hasError('invalidNumber');
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('photosAnimation', [
      transition('* => *', [
        query('div', style({ transform: 'translateX(-100%)' })),
        query(
          'div',
          stagger('400ms', [
            animate('600ms', style({ transform: 'translateX(0)' }))
          ])
        )
      ])
    ]),
    trigger('popOverState', [
      state(
        'show',
        style({
          opacity: 1
        })
      ),
      state(
        'hide',
        style({
          opacity: 0
        })
      ),
      transition('show => hide', animate('600ms ease-out')),
      transition('hide => show', animate('1000ms ease-in'))
    ]),
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translateX(0)',
          right: '0px'
        })
      ),
      state(
        'out',
        style({
          transform: 'translateX(100%)',
          right: '0px'
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ]
})
export class AppComponent implements OnInit {
  @ViewChild('firstName') firstName: ElementRef;

  title = 'web';
  disableSubmit = false;
  showExtraFields = false;
  signUpForm: FormGroup;
  submitted = false;
  submittedClearProgress = 0;
  errorMatcher = new CrossFieldErrorMatcher();
  countryCallingCodes = [];
  boostedCountryCallingCodes = [
    {
        'name': 'United Kingdom',
        'dialCode': '+44',
        'code': 'GB'
    },
    {
        'name': 'United States',
        'dialCode': '+1',
        'code': 'US'
    }
  ];
  phoneUtil = phoneLib.PhoneNumberUtil.getInstance();
  places = [];

  constructor(
    public intercom: Intercom,
    private _formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(e => {
          return activatedRoute.root.firstChild.snapshot.data;
        }),
        filter(data => data.hasOwnProperty('showExtraFields')),
        tap(data => {
          this.showExtraFields = data.showExtraFields;
        })
      )
      .subscribe();
  }

  ngOnInit() {
    // this.intercom.boot({
    //  widget: {
    //    activator: '#intercom'
    //  }
    // });

    this.angulartics2GoogleAnalytics.startTracking();

    this.signUpForm = this._formBuilder.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        countryCode: [this.boostedCountryCallingCodes[0].dialCode, [Validators.required]],
        phone: ['', [Validators.required]],
        location: ['', Validators.required],
        countryCallingCode: [this.boostedCountryCallingCodes[0], [Validators.required]],
        fullLocation: ['', Validators.required],
        referrer: [''],
      },
      {
        validator: this.validPhoneNumber()
      }
    );

    this.httpClient
      .get('https://api.runnr.io/static/country-calling-codes')
      .pipe()
      .subscribe((res: any) => {
        this.countryCallingCodes = res;
      });

    setTimeout(() => {
      this.emergencyPopup();
    }, 1000);
  }

  private _filterStates(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.countryCallingCodes.filter(
      stateb => stateb.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  emergencyPopup() {
    this.dialog
      .open(BasicPopupComponent, {})
      .afterClosed()
      .pipe()
      .subscribe((data) => {
        console.log('Dialog output:', data);
      });
  }

  submitSignUp() {
    this.disableSubmit = true;
    const requestBody = this.signUpForm.value;
    delete requestBody['countryCallingCode'];
    this.httpClient
      .post('https://12j7arhval.execute-api.us-west-2.amazonaws.com/dev/lead', requestBody)
      .pipe(
        map(res => {
          this.disableSubmit = false;

          this.submitted = true;

          const setint = setInterval(() => {
            this.submittedClearProgress += 10;
          }, 500);

          setTimeout(() => {
            this.submitted = false;
            this.submittedClearProgress = 0;

            this.signUpForm.get('firstName').reset();
            this.signUpForm.get('lastName').reset();
            this.signUpForm.get('phone').reset();
            this.signUpForm.get('location').reset();
            this.signUpForm.get('fullLocation').reset();
            this.signUpForm.get('referrer').reset();
            this.signUpForm.get('countryCode').setValue(this.boostedCountryCallingCodes[0].dialCode);
            this.signUpForm.get('countryCallingCode').setValue(this.boostedCountryCallingCodes[0]);

            this.firstName.nativeElement.focus();

            clearInterval(setint);
          }, 5000);
        }),
        catchError((error) => {
          alert('Error sending data');
          this.submitted = false;
          this.disableSubmit = false;
          return new Observable;
        })
      )
      .subscribe(res => {});
  }

  setCountryCallingCode(event) {
    this.signUpForm.get('countryCode').setValue(event.value.dialCode);
  }

  handleAddressChange(event) {
    this.signUpForm.get('fullLocation').setValue(event);
  }

  getFlag(code: string) {
    const countryCallingCode = code !== null ? code : this.signUpForm.get('countryCallingCode').value.code;
    const url =
      typeof countryCallingCode === 'string'
        ? `https://runnr-media.s3-us-west-2.amazonaws.com/static/flags/${countryCallingCode.toLowerCase()}.svg`
        : '';
    return url;
  }

  isPhoneNumberValid(phoneNumber = '', code = 'GB') {
    try {
      const parsedNumber = this.phoneUtil.parse(phoneNumber, code);
      return this.phoneUtil.isValidNumber(parsedNumber);
    } catch (error) {
      return false;
    }
  }

  validPhoneNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const phoneNumber = control.get('phone');
      if (!phoneNumber) {
        return null;
      }
      const countryCallingCode = control.get('countryCallingCode').value;
      return !this.isPhoneNumberValid(phoneNumber.value, countryCallingCode.code)
        ? { invalidNumber: true }
        : null;
    };
  }
}
