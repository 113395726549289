import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import * as phoneLib from 'google-libphonenumber';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

/**
 * Basic popup component
 */
@Component({
  selector: 'app-basic-popup',
  templateUrl: './basic-popup.component.html',
  styleUrls: ['./basic-popup.component.scss'],
})
export class BasicPopupComponent {

  submitted = false;
  submitting = false;
  signUpForm: FormGroup;
  phoneUtil = phoneLib.PhoneNumberUtil.getInstance();
  countryCallingCodes = [];
  boostedCountryCallingCodes = [
    {
        'name': 'United Kingdom',
        'dialCode': '+44',
        'code': 'GB'
    },
    {
        'name': 'United States',
        'dialCode': '+1',
        'code': 'US'
    }
  ];

  /**
   * Basic popup constructor
   */
  constructor(
    private dialogRef: MatDialogRef<BasicPopupComponent>,
    private _formBuilder: FormBuilder,
    private httpClient: HttpClient,
    ) {

      this.signUpForm = this._formBuilder.group(
        {
          firstName: ['emergency'],
          countryCode: [this.boostedCountryCallingCodes[0].dialCode, [Validators.required]],
          phone: ['', [Validators.required]],
          countryCallingCode: [this.boostedCountryCallingCodes[0], [Validators.required]],
          type: ['emergency'],
        },
        {
          validator: this.validPhoneNumber()
        }
      );

      this.httpClient
      .get('https://api.runnr.io/static/country-calling-codes')
      .pipe()
      .subscribe((res: any) => {
        this.countryCallingCodes = res;
      });
    }

  /**
   * Close popup
   */
  close(data) {
    this.dialogRef.close(data);
  }

  submit() {
    this.submitting = true;
    this.httpClient
      .post('https://12j7arhval.execute-api.us-west-2.amazonaws.com/dev/lead', this.signUpForm.value)
      .pipe(
        catchError((error) => {
          alert('Error sending data');
          this.submitting = false;
          return of();
        })
      )
      .subscribe(res => {
        this.submitting = false;
        this.submitted = true;
      });
  }

  setCountryCallingCode(event) {
    this.signUpForm.get('countryCode').setValue(event.value.dialCode);
  }

  getFlag(code: string) {
    const countryCallingCode = code !== null ? code : this.signUpForm.get('countryCallingCode').value.code;
    const url =
      typeof countryCallingCode === 'string'
        ? `https://runnr-media.s3-us-west-2.amazonaws.com/static/flags/${countryCallingCode.toLowerCase()}.svg`
        : '';
    return url;
  }

  isPhoneNumberValid(phoneNumber = '', code = 'GB') {
    try {
      const parsedNumber = this.phoneUtil.parse(phoneNumber, code);
      return this.phoneUtil.isValidNumber(parsedNumber);
    } catch (error) {
      return false;
    }
  }

  validPhoneNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const phoneNumber = control.get('phone');
      if (!phoneNumber) {
        return null;
      }
      const countryCallingCode = control.get('countryCallingCode').value;
      return !this.isPhoneNumberValid(phoneNumber.value, countryCallingCode.code)
        ? { invalidNumber: true }
        : null;
    };
  }
}
