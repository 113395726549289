import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import { MaterialManifestModule } from './material-manifest/material-manifest.module';
import { IntercomModule } from 'ng-intercom';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { Angulartics2Module } from 'angulartics2';
import { RouterModule } from '@angular/router';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    RouterModule.forRoot([
      {
        path: '',
        component: AppComponent,
        data: {
          showExtraFields: true,
        }
      }
    ]),
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MaterialManifestModule,
    IntercomModule.forRoot({
      appId: 'h99clppp',
      updateOnRouterChange: false,
    }),
    Angulartics2Module.forRoot(),
    GooglePlaceModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
